<template>
  <div>
    <v-data-table
      :headers="Headers"
      :items="contacts"
      class="elevation-1 tb px-2"
      :search="search"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-toolbar flat>
            <b>Contacts Information </b>
          </v-toolbar>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </template>
      <template #item.createdAt="{ value }">
        {{ value | formatDate }}
      </template>
      <template #item.actions="{ item }">
        <v-icon
          icon
          small
          @click="del(item)"
          title="Click to delete "
          color="error"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";
export default {
  name: "ContactsInfo",
  mixins: [snackMixin],

  data() {
    return {
      search: "",
      Headers: [
        { text: "Fullname", value: "fullname" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone" },
        { text: "Subject", value: "subject" },
        { text: "Message", value: "message" },
        { text: "Created Date", value: "createdAt" },
        { text: "", value: "actions" },
      ],
      contacts: [],
    };
  },

  created() {
    apiClient
      .get("/contacts?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.contacts = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));
  },

  methods: {
    del(item) {
      const ok = confirm(`Are sure you want to delete this ${item.fullname}?`);
      if (ok) {
        this.displayMsg(`Deleting ${item.fullname}, please wait...`);
        apiClient
          .delete(`/contacts?${item.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(
                `${item.fullname} not successfully deleted`,
                "error"
              );
              return;
            } else {
              this.contacts = this.contacts.filter((pt) => pt.id !== item.id);
              this.displayMsg(`${item.fullname} successfully deleted `);
            }
          })
          .catch((err) => this.displayMsg(err.message, "error"));
      }
    },
  },
};
</script>
<style scoped>
.tb tr,
.tb th {
  border: 1px solid black;
}
</style>
